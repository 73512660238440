import Web3 from 'web3';
import Contract from './PerseaSimpleCollectionGasless.json';
import axios from "axios";
import { contractName, getWebInstance } from 'services/ERC721/totalLeft';
import { ethers } from "ethers";

export const domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'salt', type: 'bytes32' },
  { name: 'verifyingContract', type: 'address' },
];

export const ForwardRequest = [
  { name: 'from', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

export const MetaTransactionTypes = {
  EIP712Domain: domain,
  ForwardRequest: ForwardRequest,
}

export const buildDomainRep = (name, version, chainId, contractAddress) => {
  return {
    name: name,
    version: version,
    salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32),
    verifyingContract: contractAddress,
  }
}

export const payableMintERC20Message = async (signer, gas, quantity, ProteinaLabsContract) => {
  const nonce = Number(await ProteinaLabsContract.methods.getNonce(signer).call());
  console.log('contract',ProteinaLabsContract)
  return {
    from: signer,
    value: 0,
    gas: gas,
    nonce: nonce,
    data: ProteinaLabsContract.methods.payableMintERC20(quantity).encodeABI(),
  };
}

export const signMetaTransaction = (domain, message) => {
  return {
      types: MetaTransactionTypes,
      domain: domain,
      primaryType: 'ForwardRequest',
      message: message,
  }
}

export const signMTX = async(sender, rpc, blockchain, address, provider) => {
  let web3 = new Web3(provider);
  let web3Secondary = getWebInstance(null,rpc)
  let contract = new web3Secondary.eth.Contract(Contract.abi, web3.utils.toChecksumAddress(address))
  let  new_message =  await payableMintERC20Message(sender, 500000,1, contract)
  let name = await contractName(null,web3.utils.toChecksumAddress(address),rpc)
  let  new_domain =  buildDomainRep(name, "1",(await web3Secondary.eth.getChainId()),  web3.utils.toChecksumAddress(address))

  let data = signMetaTransaction(new_domain,new_message)
  console.log('data to sig',data)
  var method = 'eth_signTypedData_v4';
  var new_params = [sender, JSON.stringify(data)];
  console.log('new_params ::',new_params)
  web3.currentProvider.sendAsync(
    {
      method : method,
      params : new_params,
     // id: Date.now(),
     // from: sender,
    }, (err, result)=> {
        console.log('signature', result)
        if (err || result.error) {
        console.error(result);
      }
        const params = {
            project_key: address,
            req : new_message,
            signature : result.result
        }
        const domain = process.env.REACT_APP_DOMAIN;
        const url = `${process.env.REACT_APP_URL_API}/relay?domain=${domain}&blockchain=${blockchain}&type=EXECUTE`
        console.log('params',params)
        axios.post(url,params).then((response) =>{
            console.log('response sign',response)
            return response
        }).catch((err) => {
          console.log('error sign',err)
        })

    })
}