import React, { Suspense, lazy, useContext } from 'react';
import { Routes as RoutesMain , Route } from 'react-router-dom';
// import Home from 'views/Home'; 
import Main from 'layouts/Main';
import { Context } from 'hooks/WalletContext';
import ContainerNetwork from 'layouts/ContainerNetwork';
import ErrorBoundary from 'components/ErrorBoundary';
import Profile from 'views/Profile';
import CollectionBuy from 'views/CollectionBuy';
import Collections from 'views/Collections';
// import CreateCollection from 'views/CreateCollection';
// import Create from 'views/Create';
// import SelectCollection from 'views/SelectCollection';
// import PreMintMassive from 'views/PreMintMassive';
const NFT = lazy(() => import('views/NFT'));
const Collection = lazy(() => import('views/Collection'));
const EditCollection = lazy(() => import('views/EditCollection'));

const Routes = () => {

    const { data } = useContext(Context);

    return (
        <React.Fragment>
        <Main>
            <RoutesMain>
            <Route path="/"
                element={
                <ContainerNetwork>
                    <Collections />
                </ContainerNetwork>
                }
            />
            <Route path="/home"
                element={
                <ContainerNetwork>
                <Collections />
                </ContainerNetwork>
                }
            />
            <Route path="/profile"
                element={
                <ContainerNetwork>
                    <Profile />
                </ContainerNetwork>
                }
            />
            <Route path="/nft"
                element={
                <ErrorBoundary fallback={<div></div>}>
                    <Suspense fallback={<div></div>}>
                        <ContainerNetwork>
                            <NFT />
                        </ContainerNetwork>
                    </Suspense>
                </ErrorBoundary>
                }
            />
            <Route path="/collection-buy"
                element={
                <ContainerNetwork>
                    <CollectionBuy />
                </ContainerNetwork>
                }
            />
        
            <Route path="/collection"
                element={
                    <ErrorBoundary fallback={<div></div>}>
                    <Suspense fallback={<div></div>}>
                        <ContainerNetwork>
                            <Collection />
                        </ContainerNetwork>
                    </Suspense>
                </ErrorBoundary>
                }
            />

            {data && data.user && data.user.role > 1 && data && data.userAccount &&
            <Route path="/create/edit-collection"
                element={
                (data && data.userAccount)  ? 
                    <ErrorBoundary fallback={<div></div>}>
                        <Suspense fallback={<div></div>}>
                            <ContainerNetwork>
                                <EditCollection />
                            </ContainerNetwork>
                        </Suspense>
                    </ErrorBoundary>
                    :
                    <Route to="/home" />
                }
            />
            }
            {/* <Route exact path="/create/create-collection"
                element={
                    <ErrorBoundary fallback={<div></div>}>
                        <Suspense fallback={"Load"}>
                            <ContainerNetwork>
                                <CreateCollection />
                            </ContainerNetwork>
                        </Suspense>
                    </ErrorBoundary>
                }
            />
            <Route exact path="/create"
                element={
                    <ErrorBoundary fallback={<div></div>}>
                    <Suspense fallback={"Load"}>
                        <ContainerNetwork>
                        <Create />
                        </ContainerNetwork>
                    </Suspense>
                </ErrorBoundary>
                }
            />    
            <Route exact path="/create/select-collection"
                element={
                    <ErrorBoundary fallback={<div></div>}>
                    <Suspense fallback={"Load"}>
                        <ContainerNetwork>
                        <SelectCollection />
                        </ContainerNetwork>
                    </Suspense>
                </ErrorBoundary>
                }
            /> */}
            {/* <Route exact path="/create/massive-pre-mint-nft"
                element={
                    <ErrorBoundary fallback={<div></div>}>
                        <Suspense fallback={"Load"}>
                            <ContainerNetwork>
                                <PreMintMassive />
                            </ContainerNetwork>
                        </Suspense>
                    </ErrorBoundary>
                }
            />  */}
  
        </RoutesMain>
    </Main>
    </React.Fragment>
  );
};

export default Routes;
