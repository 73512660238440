import React from 'react';
import PropTypes from 'prop-types';
import { nfts } from 'mocks/nfts';
import Box from '@mui/material/Box';
import CardNFT from 'components/Cards/CardNFT'
import { isMinted } from 'services/ERC721/isMinted';

const NFTs = ({content}) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    width:'100%',
                    display:'grid',
                    gridTemplateColumns:'repeat(auto-fit,minmax(210px, 1fr))',
                    gap:{xs:'10px',sm:'35px',md:'35px',lg:'35px',xl:'35px'},
                    mb:'30px',
                    '@media screen and (max-width:480px)':{
                        gridTemplateColumns:'repeat(2, 1fr)'
                    },
                    '@media screen and (max-width:380px)':{
                        gridTemplateColumns:'repeat(2, 1fr)'
                    }
                }}
            >
                {
                    content?.slice(0,6).map((item,index)=>{
                        return ( isMinted(item) &&
                            <CardNFT key={index} item={item} />
                        )
                    })
                }
            </Box>
        </React.Fragment>
    );
};

NFTs.defaultProps = {
    content:nfts
}

NFTs.propTypes = {
    content: PropTypes.array
};

export default NFTs;