
import Box from '@mui/material/Box';
import styled from '@emotion/styled'


export const ContainerMain = styled(Box)`
    height: 100%;
    background: #000;
    position: relative;
`

