import React, { useContext } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {  Box, AppBar, CardMedia  } from '@mui/material';
import { CustomNavLink } from './styles/styles';
import DesktopBar from './components/DesktopBar/DesktopBar';
import MenuIcon from '@mui/icons-material/Menu';
import MobileBar from './components/MobileBar';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import LogoTopBar from 'assets/images/proteina _fondo_blanco_negro.png';

const TopBar = () => {

    const { setOpenNav } = useContext(DrawerMobileContext);

    const handleOpenNav = () => {
        setOpenNav(true);
    }

    const items = [

    ]


    return (
        <Box
            sx={{
                "&,div,header":{
                    background:'#000',
                    height: 'auto'
                }
            }}
        >
            <AppBar position="static" sx={{boxShadow:'none', border:'none', p:'0px 0px',transition:'none',background:'#1c4851'}}>
                <Toolbar >
                    <Box
                        sx={{
                            color:'#fff',
                            fontFamily:'Futura',
                            "@media screen and (max-width: 1300px)":{
                                mr:'0px',
                                ml:'0px',
                            },
                            "@media screen and (max-width: 758px)": {
                                mr:'0px',
                                ml:'0px',
                            }
                        }}
                    > 
                        <CustomNavLink to="/home">
                            <CardMedia
                                component="img"
                                src={LogoTopBar}
                                alt="logo"
                                sx={{
                                    width:{xs:'200px',sm:'290px',md:'290px'},
                                    height:'auto',
                                    p:{xs:'2px',sm:'2px',md:'5px',lg:'5px',xl:'5px'},
                                    cursor: 'pointer',
                                    transition: 'width 0.8s, height 0.8s',
                                    position: 'relative',
                                    left: {xs:'-9px',sm:'0px'}
                                }}
                            />
                        </CustomNavLink>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { sm: 'block', md: 'none' },mr:'5px' }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleOpenNav}
                        >
                            <MenuIcon
                                htmlColor="#00ff02"
                                sx={{
                                    fontSize: '30px',
                                }} 
                            />
                        </IconButton>
                    </Box>
                    <DesktopBar tabs={items} />
                    <MobileBar tabs={items} />
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default TopBar;