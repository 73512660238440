import Web3 from 'web3'
import Contract from './PerseaSimpleCollection.json';
import ContractGassless from './PerseaSimpleCollectionGasless.json';

export const totalLeft = async(provider,address, rpc = null) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(Contract.abi,address)
    let left = await contract.methods.totalLeft().call()
    return parseInt(left)
}

export const currentERC20Address = async(provider, address, rpc=null) => {
    const web3 = getWebInstance(provider, rpc);
    const contract = new web3.eth.Contract(ContractGassless.abi,address)
    const ERC20Contract = await contract.methods.ERC20Contract().call();
    console.log('ERC20Contract', ERC20Contract);
    return ERC20Contract;
}

export const currentERC20Price = async(provider, address, rpc=null) => {
    const web3 = getWebInstance(provider, rpc);
    const contract = new web3.eth.Contract(ContractGassless.abi,address)
    const price = await contract.methods.currentERC20Price().call();
    console.log('ERC20Price', price);
    return (parseFloat(""+price) / 1000000);
}

export const contractName = async(provider, address, rpc=null) => {
    const web3 = getWebInstance(provider, rpc);
    const contract = new web3.eth.Contract(Contract.abi,address);
    const name = await contract.methods.name().call();
    console.log('ERC20Price', name);
    return name;
}


export const getCurrentPrice = async(provider,address, rpc = null) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(Contract.abi,address)
    let price = await contract.methods.currentPrice().call()
    console.log('price', price)
    return price
}

export const getCurrentPriceFormat = async(provider,address) => {
        let web3 = null;
        if(web3) {
            web3 = new Web3(provider)
        } else {
            web3 = new Web3(process.env.REACT_APP_RPC)
        }
        let contract = new web3.eth.Contract(Contract.abi,address)
        let price = await contract.methods.currentPrice().call()
        price =  web3.utils.fromWei(""+price,'ether')
        return price
}

export const getWebInstance = (provider, rpc = null) => {
    let web3 = null;
    if (provider) {
        web3 = new Web3(provider);
    } else {
        if(rpc) {
            web3 = new Web3(rpc);
        } else {
            web3 = new Web3(process.env.REACT_APP_RPC);
        }
    }
    return web3
}

export const contractPrice = async(provider,to, rpc = null) => {
    let web3 = getWebInstance(provider, rpc)
    let contract = new web3.eth.Contract(Contract.abi,web3.utils.toChecksumAddress(to));
    let price = await contract.methods.currentPrice().call()
    return  web3.utils.fromWei(""+price,'ether')
}

