
import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import styled from "@emotion/styled/macro";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getThumbnail } from 'services/Thumbail/getThumbnail';
import PropTypes from 'prop-types';

const Background = styled(Card)({
    borderRadius: '0px 0px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    position: "relative",
    width:'100%',
    height:'280px',
    cursor: "pointer",
    backgroundColor: "transparent",
    [`:hover ${CardMedia}`]: {
      backgroundColor: "rgba(0,0,0,.5)",
    },
    [`:hover ${CardMedia}`]: {
      opacity: 1,
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        /* select class */
        '& .card-collection': {
          transform: 'scale(1.06)',
        },
        '& .is-video-collection': {
          transform: 'scale(3.08)',
        }
    },
    '@media screen and (max-width:600px)':{
        minHeight:'180px',
        maxHeight:'180px'
    },
    '@media screen and (max-width:480px)':{
        minHeight:'170px',
        maxHeight:'170px'
    },
    '@media screen and (max-width:380px)':{
        minHeight:'170px',
        maxHeight:'170px'
    },
});

const CardNFT = ({item}) =>{
    const { t } = useTranslation("translate");
    return (
        <Box 
            sx={{
                display:'grid',
                width:'100%',
                height:'100%',
                gridTemplateColumns:'repeat(1,1fr)',
                m:'0 auto',
                borderRadius:'8px',
                maxWidth:{xs:'172px',sm:'240px',md:'235px',lg:'250px',xl:'330px'},
                background:'rgba(0,0,0,0.4)',
                '@media screen and (max-width:480px)':{
                    maxWidth:'100%'
                },
                '@media screen and (max-width:380px)':{
                    maxWidth:'100%'
                }
            }}
        >   
            <Box component='section' 
                sx={{
                    m:'0 auto',
                    width:'100%',
                    minHeight:{xs:'180px',sm:'280px',md:'280px',lg:'280px',xl:'280px'},
                    maxHeight:{xs:'180px',sm:'280px',md:'280px',lg:'280px',xl:'280px'},
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    '@media screen and (max-width:480px)':{
                        minHeight:'170px',
                        maxHeight:'170px'
                    },
                    '@media screen and (max-width:380px)':{
                        minHeight:'170px',
                        maxHeight:'170px'
                    }
                }}
            >        
                <Background>
                    <Link 
                        to={`/nft?address=${item.project_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                    {
                        item && item.metadata.is_video ?
                        <CardMedia
                            className={'card-collection'}
                            component={'img'}
                            src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                            autoPlay
                            loop
                            muted
                            sx={{
                                position:'relative',
                                borderRadius: '8px 8px 0px 0px',
                                height:'100%',
                                width:'100%',
                                margin:'0 auto'
                            }}
                        />
                        :
                        <CardMedia
                            className={'card-collection'}
                            component={'img'}
                            src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                            autoPlay
                            loop
                            muted
                            sx={{
                                position:'relative',
                                borderRadius: '8px 8px 0px 0px',
                                height:'100%',
                                width:'100%',
                                margin:'0 auto',
                                "&:hover":{
                                    transform:'scale(2)'
                                }
                            }}
                        />
                    }
                    </Link>
                </Background>
            </Box>
            <Box
                sx={{
                    display:'grid',width:'100%',height:'auto',gridTemplateColumns:'repeat(1,1fr)',background:'#151515',borderRadius:'0px 0px 8px 8px'
                }}
            >
                <Box
                    sx={{width:'100%',display:'flex',justifyContent:'space-between',color:'#fff',p:'0px 10px'}}
                >
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                            mt:'5px',
                            width:'calc(55% + 10px)'
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                color:'#ffff',
                                margin:'0px 0px',
                                width:'calc(100% - 1px)',
                            }}
                        >
                            <svg width="13" height="13" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.542969" width="12" height="12" rx="6" fill="#4E4E4E"/>
                                <path d="M6.58538 9.08C6.07338 9.08 5.62004 9.00533 5.22538 8.856C4.83071 8.70667 4.51604 8.472 4.28138 8.152C4.05204 7.832 3.92671 7.41867 3.90538 6.912C3.90004 6.69333 3.89738 6.45867 3.89738 6.208C3.89738 5.95733 3.90004 5.71733 3.90538 5.488C3.92671 4.992 4.05471 4.584 4.28938 4.264C4.52938 3.944 4.84671 3.70667 5.24138 3.552C5.64138 3.39733 6.08938 3.32 6.58538 3.32C6.92671 3.32 7.25471 3.36 7.56938 3.44C7.88938 3.51467 8.17738 3.63467 8.43338 3.8C8.68938 3.96533 8.89204 4.17867 9.04138 4.44C9.19604 4.696 9.27604 5.00533 9.28138 5.368C9.28138 5.416 9.26271 5.45867 9.22538 5.496C9.19338 5.528 9.15338 5.544 9.10538 5.544H7.47338C7.38804 5.544 7.32404 5.528 7.28138 5.496C7.24404 5.45867 7.20938 5.39733 7.17738 5.312C7.11871 5.14133 7.03871 5.02667 6.93738 4.968C6.83604 4.90933 6.71604 4.88 6.57738 4.88C6.40138 4.88 6.26004 4.928 6.15338 5.024C6.05204 5.11467 5.99604 5.28267 5.98538 5.528C5.96938 5.96533 5.96938 6.41333 5.98538 6.872C5.99604 7.11733 6.05204 7.288 6.15338 7.384C6.26004 7.47467 6.40138 7.52 6.57738 7.52C6.71604 7.52 6.83604 7.488 6.93738 7.424C7.04404 7.36 7.12404 7.248 7.17738 7.088C7.20404 6.99733 7.23871 6.936 7.28138 6.904C7.32404 6.872 7.38804 6.856 7.47338 6.856H9.10538C9.15338 6.856 9.19338 6.87467 9.22538 6.912C9.26271 6.944 9.28138 6.984 9.28138 7.032C9.27604 7.39467 9.19604 7.70667 9.04138 7.968C8.89204 8.224 8.68938 8.43467 8.43338 8.6C8.17738 8.76533 7.88938 8.888 7.56938 8.968C7.25471 9.04267 6.92671 9.08 6.58538 9.08Z" fill="white"/>
                            </svg>
                            <Tooltip 
                                title={
                                    item && item.project && item.project.name 
                                }
                                placement="top"
                            >
                                <Box
                                    component="small"
                                    sx={{
                                        width:'calc(100% - 1px)',
                                        color:'#FFFFFF',
                                        fontWeight: 400,
                                        opacity:0.5,
                                        fontSize:'12px',
                                        margin:'0px 0px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {item && item.project && item.project.name}
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            width:'calc(55% - 10px)'
                        }}
                    >
                        <Box
                            sx={{color:'#ffff',margin:'0px 0px',display:'none'}}
                        >
                            <h4 style={{margin:'0px 0px',fontSize:'12px',color:'#FFFFFF',opacity:0.5}}>
                                {t("cards.sale.price_text")}
                            </h4>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{width:'100%',display:'flex',justifyContent:'space-between',color:'#fff',p:'0px 10px'}}
                >
                    <Tooltip 
                        title={
                            item && item.metadata && item.metadata.json_data && item.metadata.json_data && item.metadata.json_data.name ?
                            item.metadata.json_data.name
                            :
                            'Not available'
                        }
                        placement="top"
                    >
                        <Box
                            sx={{
                                width:{xs:'calc(50% + 8px)',sm:'calc(50% + 8px)',md:'calc(50% + 8px)',lg:'calc(50% + 8px)',xl:'calc(50% + 8px)'},
                                color:'#ffff',
                                margin:'0px 0px',
                                fontSize:{xs:'12px',sm:'14px',md:'16px',lg:'18px',xl:'19px'},
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}
                        >
                            {item && item.metadata && item.metadata.json_data && item.metadata.json_data && item.metadata.json_data.name} 
                        </Box>
                    </Tooltip>
                    <Tooltip 
                        style={{display : 'none'}}
                        title={'ETH '+item && item.sale && item.sale.price}
                        placement="top"
                    >
                    <Box
                        sx={{
                            width:{xs:'calc(50% - 10px)',sm:'calc(50% - 10px)',md:'calc(50% - 10px)',lg:'calc(50% - 10px)',xl:'calc(50% - 10px)'},
                            color:'#ffff',
                            margin:'0px 0px',
                            fontSize:{xs:'12px',sm:'14px',md:'16px',lg:'18px',xl:'19px'},
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            textAlign:'right'
                        }}
                    >
                        {/* {
                            item && item.sale && item.sale.coin +' '
                        } */}
                        ETH
                        {
                            ' '
                        }
                        {
                            item && item.sale && item.sale.price
                        }
                    </Box>
                    </Tooltip>
                    <Box
                        sx={{
                            display:'none',
                            gridTemplateColumns:'repeat(1,1fr)'
                        }}
                    >
                        
                        <Box
                            sx={{
                                display:'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap:'5px',
                                width:'auto',
                                fontSize:{xs:'13px',sm:'16px',md:'19px',lg:'22px',xl:'20px'},
                                maxWidth:{xs:'90px',sm:'90px',md:'90px',lg:'100px',xl:'100px'},
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}
                        >
                            
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{width:'100%',display:'flex',justifyContent:'space-between',color:'#fff',p:'8px 10px'}}
                >
                    <Box
                        sx={{
                            display:'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end'
                        }}
                    >
                        <AvatarGroup total={5}  max={3}
                            sx={{
                                display:'none',
                                mb:'8px',
                                "&>.MuiAvatarGroup-root ,.MuiAvatar-root":{
                                    width:{xs:15,sm:17,md:19,lg:22,xl:24},
                                    height:{xs:15,sm:17,md:19,lg:22,xl:24} 
                                }
                            }}
                        >
                            <Avatar  alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                            <Avatar  alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                            <Avatar  alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                            <Avatar  alt="Agnffes Walker" src="/static/images/avatar/5.jpg" />
                        </AvatarGroup>
                    </Box>
                    <Box
                        sx={{
                            display:'none',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Box
                            component="h3"
                            sx={{
                                margin:'0px 0px',
                                fontSize:{xs:'14px',sm:'16px',md:'18px',lg:'20px',xl:'20px'}
                            }}
                        >
                            {t("cards.sale.buy_now")}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

CardNFT.propTypes = {
    item: PropTypes.object
}

export default CardNFT;
