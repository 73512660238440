import styled from '@emotion/styled';

export const BannerContainer = styled.div`
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    /* sx={{
                                mt: 2,
                                minWidth:'100%',
                                width: '100%',
                                height: { xs: '160px', sm: '290px', md: '340px', lg: '480px', xl: '560px' },
                                "@media screen and (max-width:780px)":{
                                    height: '220px', 
                                },
                                "@media screen and (max-width:600px)":{
                                    height: '160px', 
                                },
                                "@media screen and (max-width:350px)":{
                                    height: '120px', 
                                }
                            }} */
    margin-top: 10px;
    min-width: 100%;
    width: 100%;
    height: 100%;
    
    
`;

export const BannerContent = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    top: 2px;
    height: 100%;
    padding: 0px 20px 25px;
    //transform: translate(-50%, -50%);
    //media query ipad
    /* @media (max-width: 1024px) {
        top: 50%;
        left: 50%;
    } */
`;
