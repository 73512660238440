import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NFTs from './components/NFTs';
import { useFetch } from 'hooks/useFetch';
import {Link} from 'react-router-dom';
import LoaderCircle from 'components/LoaderCircle';
import ErrorMessage from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';
// import LogoBackground from 'assets/images/background/party_background2.png';
import { layoutWhitetList } from 'api/sanity';
//import LogoBackground from 'assets/images/background/storm.jpg';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
/* imports para carousel */
import "react-multi-carousel/lib/styles.css";
import CarouselSimple from 'components/Carousel/CarouselSimple';
import { Typography } from '@mui/material';
import BlockContent from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';

const handleResize = (size) => {
    if(size <= 600){
        return "mobile";
    }else
    if(size > 600 && size < 1024){
        return "tablet";
    }else
    if(size > 1023 && size < 3000){
        return "desktop"
    }else
    if(size >= 3000){
        return "big";
    }
}

const Background = ({sanityLayout}) => {
    return (
        <React.Fragment>
            {
                sanityLayout && sanityLayout.background && sanityLayout.background.asset && sanityLayout.background.asset.url && 
                <Box
                    component={"img"}
                    src={sanityLayout.background.asset.url} alt=""
                    sx={{
                        display:{ xs:'none', sm:'block' },
                        alignSelf: 'stretch',
                        flex: 1,
                        position: 'absolute',
                        margin:' 0 !important',
                        top: '0px',
                        left: '0px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        maxHeight: '100%',
                        objectFit: 'cover',
                        objectPosition:'center',
                        width: '100%',
                        height: '100%',
                        zIndex: '0'

                    }} 
                />
            }

            {
                sanityLayout && sanityLayout.backgroundMobile && sanityLayout.backgroundMobile.asset && sanityLayout.backgroundMobile.asset.url && 
                <Box
                    component={"img"}
                    src={sanityLayout.backgroundMobile.asset.url} alt=""
                    sx={{
                        display:{ xs:'block', sm:'none' },
                        alignSelf: 'stretch',
                        flex: 1,
                        position: 'absolute',
                        margin:' 0 !important',
                        top: '0px',
                        left: '0px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        maxHeight: '100%',
                        objectFit: 'cover',
                        objectPosition:'center',
                        width: '100%',
                        height: '100%',
                        zIndex: '0'

                    }} 
                />
            }
          
        </React.Fragment>
    )
}

Background.propTypes = {
    sanityLayout: PropTypes.any
}


const Collections = () => {
    const { t } = useTranslation("translate");
    const url = `${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&limit=300&page=0&order=created`;
    const { data: projects, error, loading } = useFetch(url);
    const limitNFTRef = React.useRef(6);
    const pageNftRef = React.useRef(0);
    const urlNft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=${pageNftRef.current}&order=created`
    const { data: nftData, error:errorNFt, loading:loadingNFT} = useFetch(urlNft);
    let width = window.innerWidth;
    const [typeDevice,setTypeDevice] = React.useState(handleResize(width));
    const [sanityLayout,setSanityLayout] = React.useState(null);

    const initSAnity = () => {
        layoutWhitetList()
        .then((response) => {
            if (response) {
                setSanityLayout(response[0]);
            }
        })
        .catch((error) => {
            console.error(error);
            setSanityError(error);
        })
    }

    React.useEffect(() => {
        window.addEventListener("resize", function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
    
        window.removeEventListener("resize",function(){
            width = window.innerWidth;
            setTypeDevice(handleResize(width));
        });
    },[width]);

    React.useEffect(() => {
        initSAnity();
    },[]);
    
    return (
        <Box
            sx={{
                position:'relative',
                alignSelf: 'stretch',
                width:'100%',
                minHeight: '100vh',
                overflow: 'hidden',
                boxSizing: 'border-box'
            }}
        >
            <Background sanityLayout={sanityLayout}/>
            <Container maxWidth="xl">
                <Box 
                    sx={{width:'100%',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '32px',
                        lineHeight: '100%',
                        color:'#00ff02',
                        paddingTop:'50px',
                        paddingBottom:'20px',
                        textTransform:'uppercase',
                        position:'relative'
                    }}
                >
                    {sanityLayout && sanityLayout.title ?  sanityLayout.title:''}
                </Box>
                <Box 
                    sx={{width:'100%',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '20px',
                        lineHeight: '100%',
                        color:'#00ff02',
                        paddingBottom:'15px',
                        position:'relative',
                        '&,p':{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '20px',
                            lineHeight: '100%',
                            color:'#00ff02',
                        }
                    }}
                >
                    {sanityLayout && sanityLayout.description ?
                      <BlockContent blocks={sanityLayout.description} />
                    :''
                    }
                </Box>
                {
                    loading && <Box display="flex" alignItems="center" justifyContent="center" sx={{   position:'relative',minHeight: '90vh' }}>
                    <LoaderCircle text={t("collection_buy_view.loading")} />
                    </Box>
                }
                {
                    error && !loading &&
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{  position:'relative', minHeight: '90vh' }}>
                        <ErrorMessage error={error} />
                    </Box>
                }
                {
                    !error && !loading && projects &&
                    <CarouselSimple 
                        deviceType={typeDevice}
                        content={projects}
                        width={5555} 
                        type={"collections"} 
                    />
                }
                {
                    !error && !loading && projects &&
                    <Box sx={{  position:'relative',display:'grid',width:'100%',gridTemplateColumns:'repeat(1,1fr)',mt:'50px'}}>
                        {
                            !errorNFt && nftData && nftData.length > 0  && !loadingNFT &&
                            <Box
                                sx={{width:'100%',display:'flex',justifyContent:'space-between'}}
                            >
                                <Box 
                                    sx={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '24px',
                                        lineHeight: '100%',
                                        color:'#FFFFFF',
                                        "@media screen and (max-width:430px)":{
                                            fontSize: '18px',
                                        }
                                    }}>
                                    {t("collections.featured_collectibles")}
                                </Box>
                                <Box 
                                    sx={{
                                        display:'none',alignItems: 'center',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        lineHeight: '100%',
                                        color:'#FFFFFF',
                                        opacity: 0.5,
                                        cursor:'pointer',
                                        "&:hover":{
                                            opacity: 1
                                        },
                                        "@media screen and (max-width:430px)":{
                                            fontSize: '15px',
                                        }
                                    }}>
                                        <Link to={"/marketplace"} style={{textDecoration:'none',color:'#fff'}}> {t("collections.see_more")}</Link>
                                </Box>
                            </Box>
                        }
                        <Box sx={{width:'100%',mt:'20px'}}>
                            {
                                !errorNFt && nftData && !loadingNFT && nftData.length > 0 && nftData.filter((nft) => nft.transaction == "PRE_MINT").length == 0 &&
                                <NFTs content={nftData} />
                            }
                            {
                                !errorNFt && nftData && !loadingNFT && nftData.length > 0 &&
                                nftData.filter((nft) => nft.transaction == "PRE_MINT").length > 1 && 
                                <Container maxWidth="md" sx= {{ mt: 5 }}>
                                    <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >
                                        {t("nft-screen.nft_not_available")}
                                    </Typography>
                                </Container>
                            }
                            {/* {
                                !errorNFt && nftData && !loadingNFT && !error && !loading && nftData.length == 0 &&
                                <Container maxWidth="md" sx= {{ mt: 5 }}>
                                    <Typography gutterBottom variant="h4" component="h4" sx={{color:'#fff'}} >
                                        {t("nft-screen.nft_not_available")}
                                    </Typography>
                                </Container>
                            } */}
                            {
                                !errorNFt && loadingNFT && 
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <LoaderCircle text={t("collection_buy_view.loading")} />
                                </Box>
                            }
                            {
                                !loadingNFT && errorNFt && 
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <ErrorMessage error={errorNFt} />
                                </Box>
                            }
                        </Box>
                    </Box>
                }
                
            </Container>
        </Box>
       
    )
}

export default Collections;