import { ethers } from "ethers";
import Web3 from 'web3';
import axios from "axios";
import usdcContract from './usdc.json';
import { currentERC20Address, getWebInstance } from "services/ERC721/totalLeft";
export const addressUSDC = {
  137 : "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
  80001 : "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23"
}

export const domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'verifyingContract', type: 'address' },
  { name: 'salt', type: 'bytes32' },
]

export const MetaTransaction = [
  { name: 'nonce', type: 'uint256' },
  { name: 'from', type: 'address' },
  { name: 'functionSignature', type: 'bytes' },
]

export const MetaTransactionTypes = {
  EIP712Domain: domain,
  MetaTransaction: MetaTransaction,
}

export const buildDomainRep = (chainId, contractAddress) => {
  return {
    name: "USD Coin (PoS)",
    version: "1",
    verifyingContract: contractAddress,
    salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32)
  }
}

export const approveFunctionMessage = async (signer, spender, approvedAmount, USDCContract) => {
  const nonce = Number(await USDCContract.methods.nonces(signer).call());
  return {
    nonce: (nonce).toString(),
    from: signer,
    spender: spender,
    functionSignature: USDCContract.methods.approve(spender,(approvedAmount * 1000000)).encodeABI(),
  }
}

export const signMetaTransaction = (domain, message) => {
  return {
      types: MetaTransactionTypes,
      domain: domain,
      primaryType: 'MetaTransaction',
      message: message,
  }
}

export const signApprove = async(sender,spender, amount, rpc, blockchain, provider) => {
  let web3 = new Web3(provider);
  let web3Secondary = getWebInstance(null,rpc)
  let address =  await currentERC20Address(null,spender,rpc)
  let contract = new web3Secondary.eth.Contract(usdcContract.abi, web3.utils.toChecksumAddress(address))
  console.log('contract', contract)
  const approve = await approveFunctionMessage(sender,web3.utils.toChecksumAddress(spender), amount,contract)
  console.log('approve', approve)
  let data =   signMetaTransaction(buildDomainRep(await web3Secondary.eth.getChainId(),address),approve)
  console.log('data', data)
  const method = 'eth_signTypedData_v4';
  const new_params = [sender, JSON.stringify(data)];
  return new Promise((resolve, reject) =>{
      web3.currentProvider.sendAsync(
        {
          method : method,
          params : new_params,
          id: Date.now(),
        // from: sender,
        }, (err, result)=> {
            console.log('signature', result)
            if (err || result.error) {
              reject(err)
            }
          const splitSignature = ethers.utils.splitSignature(result.result)
          console.log('splitSignature',splitSignature)
          const params = {
              ...splitSignature,
              project_key :address ,
              userAddress : sender,
              functionSignature :approve.functionSignature,
          }
            const domain = process.env.REACT_APP_DOMAIN;
            const url = `${process.env.REACT_APP_URL_API}/relay?domain=${domain}&blockchain=${blockchain}&type=APPROVE`
            console.log('params',params)
            axios.post(url,params).then((response) =>{
                console.log('response sign',response)
                resolve(response.data)
            }).catch((err) => {
              reject(err)
              console.log('error sign',err)
            })
    
        })

  })
}


